import { inject} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from '@angular/router';
import { tap, zip} from 'rxjs';
import { Store } from '@ngrx/store';
import { getUserRoles, isLoggedIn } from '../../auth/auth.selectors';


export const AuthGuard = (
  route: ActivatedRouteSnapshot) => {
    const router = inject(Router);
    const service = inject(Store);
    return zip(
      service.select(isLoggedIn),
      service.select(getUserRoles)
    ).pipe(
      tap((values) => {
        if (!values[0]) {
          router.navigateByUrl('/login');
          return false;
        }

        if (!!route.data['role'] && !values[1]?.some(v => (route.data['role'] as Array<string>).includes(v))) {
          // Si no tiene los permisos necesarios pero esta logueado. Si puedo lo mando a la pantalla default.
          if (values[1]?.includes('operations') || values[1]?.includes('monitor')) {
            router.navigateByUrl('/monitor');
          } else  if (values[1]?.includes('logistics') || values[1]?.includes('treasury') || values[1]?.includes('inspector') || values[1]?.includes('reception') || values[1]?.includes('orderNotification')) {
            router.navigateByUrl('/orders');
          } else if (values[1]?.includes('admin')) {
            router.navigateByUrl('/users');
          } else if (values[1]?.includes('CashManagement')) {
            router.navigateByUrl('/conciliation');
          } else if (values[1]?.includes('customerPayments')) {
            router.navigateByUrl('/service-payments');
          }

          return false;
        }

        return true;
    }
  ));
}
